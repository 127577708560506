.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--color-white);
    width: auto;
    height: auto;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08);
    padding: 6px 12px;
    border-radius: 8px;
    position: relative;

    @media (min-width: 1440px) {
        // TODO: check figma
        padding: 10px 16px; // was 1280
    }
}
.tag--disabled .tag__text {
    color: var(--color-chateau) !important;
    cursor: default;
}
.tag__in-collection-arrow {
    width: 11.2px !important;
    height: 8px !important;

    @media (min-width: 1024px) {
        display: none;
    }
}
span.tag__in-collection-text {
    font: var(--font-caption-regular-comp);
    color: var(--color-black);
    display: none;

    @media (min-width: 1024px) {
        display: flex;
    }
}
span.tag__text {
    font: var(--font-caption-regular-comp);
}

.tag.tag--tag-icon {
    width: 48px;
    height: 36px;
    padding: 4px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    border-radius: 8px;

    @media only screen and (max-width: 1439px) {
        width: 38px;
        height: 28px;
    }
    img {
        width: 20px;
        height: 20px;

        @media only screen and (max-width: 1439px) {
            width: 16px;
            height: 16px;
        }
    }
}

.tag.tag--tag-icon.tag--isHeader {
    width: 32px;
    height: 24px;
    border-radius: 6px;

    img {
        width: 17px;
        height: 17px;
    }
}

.tag.tag--tag-icon.tag--in-collection {
    padding: 9px 14px 9px 14px;
    width: auto;
    gap: 5px;
    font: var(--font-caption-regular-comp);
}

.tag.tag--tag-rating {
    height: 36px;
    padding: 4px 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    border-radius: 8px;
    font-weight: 700;

    color: var(--color-black);
    @media only screen and (max-width: 1439px) {
        width: 45px;
        height: 28px;
    }
}
.tag.tag--tag-rating .tag__text {
    font: var(--font-h2-wide);
    color: var(--color-black);
    @media only screen and (max-width: 1439px) {
        font: var(--font-p2-medium-comp);
    }
}

.tag.tag--tag-label {
    height: 36px;
    padding: 4px 16px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    border-radius: 8px;

    color: var(--color-black);
    @media only screen and (max-width: 1439px) {
        height: 28px;
    }
}

.tag.tag--tag-label .tag__text {
    font-weight: 700;
    font-size: 17px;
    font-family: 'MTSCompactMedium';
    text-transform: uppercase;
    @media only screen and (max-width: 1439px) {
        font-size: 12px;
    }
}
.tag.tag--tag-label--large {
    padding: 0;
    border: none;
    background: none;
    color: var(--color-alabaster);
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: none;
    border-radius: 0;

    & img {
        filter: invert(1);
        margin-right: 8px;
        width: 32px;
        height: 32px;
    }
}
.tag.tag--tag-label--large .tag__text {
    font-weight: 400;
    font-size: 28px;
    font-family: 'MTSCompactMedium';
    line-height: 28px;
    @media only screen and (max-width: 767px) {
        font-size: 20px;
    }
}

.tag.tag--tag-transparent {
    border-radius: 8px;
    background-color: var(--color-background-light);
    padding: 4px 8px;
}
.tag.tag--tag-transparent .tag__text {
    color: var(--color-alabaster);
    font: var(--font-p2-regular-comp);
    @media (max-width: 768px) {
        font: var(--font-p3-regular-comp);
    }
    @media (max-width: 1919px) and (min-width: 1024px) {
        font: var(--font-p3-regular-comp);
    }
}

.tag.tag--tag-transparent.tag--clickable {
    cursor: pointer;
}
.tag.tag--tag-transparent.tag--selected {
    background: var(--color-mts-company);
}
