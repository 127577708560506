@use "sass-rem" as rem;
.game-card__platforms-tags {
    margin: var(--platforms-margin);
    display: flex;
    flex-direction: row;
    gap: rem.convert(8px);
    align-items: center;
}

.game-card--mobile {
    .game-card__platforms-tags {
        margin: 0 0 16px 0;
    }
}

.game-card__platforms-tags-mobile{
    margin: 0 0 16px 0;
}

.game-card--header.game-card--horizontSmall {
    .game-card__platforms-tags {
        margin: 0 0 16px 0;
    }
}