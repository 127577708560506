@import 'src/styles';

.news-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    position: relative;
}

.news-card__tag-row {
    position: absolute;
    padding: 20px;
    display: flex;
    gap: 8px;
}

.news-card__image {
    border-radius: 16px;
    width: 100%;
    height: 180px;
    object-fit: cover;
    background-color: rgba(#626c77, 0.25);
}

.news-card__title-row {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.news-card__title {
    font: var(--font-p2-medium-comp);
    font-family: 'MTSWideMedium';
    color: var(--color-alabaster);
}

.news-card__date {
    font: var(--font-p2-regular-comp);
    color: var(--color-chateau);
}

.news-card__description {
    font: var(--font-p2-regular-comp);
    color: var(--color-alabaster);
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

@include mq-tablet-768-up {
    .news-card__image {
        height: 185px;
    }
}

@include mq-desktop-1024-up {
    .news-card__image {
        height: 156px;
    }
}

@include mq-desktop-1440-up {
    .news-card__title {
        font: var(--font-h3-wide);
    }

    .news-card__date {
        font: var(--font-p1-regular-comp);
    }

    .news-card__image {
        height: 194px;
    }
}

@include mq-desktop-1920-up {
    .news-card__image {
        height: 220px;
    }
}
